<template>
  <master-layout :showContentHeader="false">
    <ion-grid class="ion-no-padding">
      <ion-row class="header">
        <ion-col>
          <ion-row>
            <ion-col class="ion-text-center">
              <h5>Seleziona chi vuoi valutare</h5>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <swiper
                :effect="'coverflow'"
                :centeredSlides="true"
                :slidesPerView="'auto'"
                :coverflowEffect="{
                  rotate: 15,
                  stretch: 30,
                  depth: 500,
                  modifier: 1,
                  slideShadows: false,
                }"
                class="my-swiper"
              >
                <swiper-slide
                  part="swiper-slide"
                  v-for="user in users"
                  :key="user.id"
                  class="slide"
                  :class="{ selected: user.selected }"
                  @click="toggleSelected(user)"
                >
                  <ion-grid>
                    <ion-row>
                      <ion-col>
                        <img
                          v-if="user.avatar_url"
                          @click="openModal(user)"
                          :src="user.avatar_url"
                        />
                        <img
                          v-else
                          src="../../public/assets/img/avatar_placeholder.png"
                        />
                        <ion-icon
                          class="valutato selected"
                          v-if="user.valutato"
                          :icon="checkmarkCircle"
                        ></ion-icon>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </swiper-slide>
              </swiper>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              {{ currentUser.nome }} {{ currentUser.cognome }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-center">
              <h5>Survey valutazione collaboratori</h5>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <swiper
            @swiper="setControlledSwiper"
            :effect="'cube'"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :cubeEffect="{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }"
          >
            <swiper-slide
              part="swiper-slide"
              v-for="question in questions"
              :key="question"
              class="slide-questions"
            >
              <ion-row>
                <ion-col class="align-center">
                  <ion-card class="quiz-question quiz">
                    {{ question.question }}
                  </ion-card>
                  <ion-card
                    v-for="answer in question.answers"
                    :key="answer"
                    class="quiz quiz-item"
                    :class="{ 'quiz-answer-selected': answer.selected }"
                    @click="selectAnswer(answer, question)"
                  >
                    {{ answer.answer }}
                  </ion-card>
                </ion-col>
              </ion-row>
            </swiper-slide>
          </swiper>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row class="ion-justify-content-center button-fixed">
      <ion-col size="auto" class="ion-margin">
        <ion-button @click="invia"> Invia </ion-button>
      </ion-col>
    </ion-row>
  </master-layout>
</template>

<script>
import ApiService from "../common/service.api"
import { translate } from "../common/helper.auth"

import "swiper/swiper-bundle.min.css"
import "swiper/components/pagination/pagination.scss"
import "@ionic/vue/css/ionic-swiper.css"

import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"

import { checkmarkCircle, personOutline } from "ionicons/icons"

import {
  IonIcon,
  IonButton,
  IonCard,
  IonImg,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonicSwiper,
} from "@ionic/vue"

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination])

export default {
  name: "EvalOneToMany",
  components: {
    IonIcon,
    IonButton,
    IonCard,
    IonImg,
    IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    Swiper,
    SwiperSlide,
  },
  created() {
    ApiService.get(`User`).then((res) => {
      console.log(res.data)
      res.data.forEach((element) => {
        element.selected = false
        this.users.push(element)
      })
    })
    this.questions.forEach((element) => {
      element.answers = [
        { answer: "Mediocre", selected: false },
        { answer: "Sufficiente", selected: false },
        { answer: "Buona", selected: false },
        { answer: "Ottima", selected: false },
      ]
    })
  },
  data() {
    return {
      swiper: null,
      personOutline,
      checkmarkCircle,
      users: [],
      questions: [
        {
          question:
            "Valutare la sua capacità di impegnarsi per fare in modo che i risultati di ciò che fa siano consolidati e possano essere di supporto al suo team?",
        },
        {
          question:
            "Qual è la sua capacità di misurare e monitorare i risultati al fine di anticipare i problemi  e garantire una prestazione in linea con le attese?",
        },
        {
          question:
            "Valutare la sua capacità interpersonale per la gestione delle relazioni con i suoi colleghi al fine di migliorare il clima aziendale",
        },
      ],

      currentUser: {},
      result: [],
    }
  },
  methods: {
    setControlledSwiper(swiper) {
      this.swiper = swiper
    },
    toggleSelected(item) {
      if (this.currentUser) {
        this.currentUser.selected = false
        this.currentUser = item
      } else {
        this.currentUser = item
      }

      item.selected = !item.selected
      this.swiper.slideTo(0)
    },
    selectAnswer(answer, question) {
      question.answers.forEach((element) => {
        element.selected = false
      })
      answer.selected = true
      this.swiper.slideNext()

      // console.log(this.$refs.swiperTransitionist.$el);
      // this.$refs.swiperTransitionist.$el.slideNext();
    },
    invia() {
      this.currentUser.valutato = true
      this.questions.forEach((question) => {
        question.answers.forEach((answer) => {
          answer.selected = false
        })
      })
    },
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

ion-grid {
  padding-bottom: 100px;
}
.my-swiper {
  padding-top: 1rem;
  height: 20vh;
}

/* img {
  position: absolute;
  border: 3px solid grey;
  width: 100%;
  height: auto;
} */

.slide {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.slide img {
  width: 100%;
  filter: grayscale(100%);
  border-radius: 50%;
  border: 5px solid grey;
}

.selected img {
  filter: grayscale(0%);
}

.selected img {
  border: 5px solid var(--ion-color-secondary);
}

.is-sent {
  position: absolute;
  top: 40px;
  left: 30px;
  color: var(--ion-color-secondary);
  font-size: 2rem;
  transform: rotate(20deg);
}

.header {
  /* position: fixed; */
  background: #fff;
  width: 100%;
}

.m-top-240 {
  margin-top: 200px;
}

ion-card {
  color: var(--text-color-light);
}

.quiz-question {
  width: 90%;
  padding: 1rem 2rem;
  font-weight: bolder;
  margin-top: 2rem;
}

.quiz-item {
  text-align: center;
  width: 90%;

  padding: 0.5rem 1rem;
  background: var(--ion-color-primary);
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-answer-selected {
  background: var(--ion-color-secondary);
}

.valutato {
  position: absolute;
  top: 75px;
  left: 50px;
  color: var(--ion-color-secondary);
  font-size: 2rem;
  transform: rotate(20deg);
}

.quiz {
  color: #ecedff;
  border-radius: 2rem;
  margin: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.quiz-question {
  padding: 1.5rem 1rem;
  margin: 3rem 0 1.5rem;
}

.slide-questions {
  background: #fff;
  position: relative;
  width: 100%;
}

.button-fixed {
  display: grid;
  place-items: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  /* background: #fff; */
  width: 100%;
}
</style>
